<template>
<v-row class="fill-height">
    <v-col cols="12" class="s-col-form" style="position:fixed;z-index:2; ">
        <v-card class="center pt-4" elevation="4">
            <v-row justify="center" >
                <!-- <v-divider vertical></v-divider> -->
                <v-col cols="4" class="s-col-form" style="margin-top:-2px">
                    <v-btn text elevation="0" small icon @click="previewCotizar()" title="Generar Cotización">
                        <v-icon style="font-size:20px; border-radius: 10px;color:red"> fas fa-comments-dollar</v-icon>
                    </v-btn>Generar Cotizaciòn
                </v-col>
                <!-- <v-divider vertical></v-divider> -->

               <!--  <v-divider vertical></v-divider> -->

                <v-col cols="4" class="s-col-form" style="margin-top:-2px">
                    <v-btn text elevation="0" small icon @click="previewSap()" v-if="$fun.isAdmin()" title="Generar Orden de Compra / Orden de Servicio">
                        <v-icon style="font-size:36px; border-radius: 10px;color:green"> mdi mdi-cart</v-icon>
                    </v-btn>Genear OC/OS
                </v-col>
                <!-- <v-divider vertical></v-divider> -->
            </v-row>
        </v-card>
    </v-col>

    <v-col class="mt-6">

        <v-sheet height="64">
            <v-toolbar flat>
                <v-btn fab text small color="grey darken-2" @click="$refs.calendar.prev()">
                    <v-icon small>
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn fab text small color="grey darken-2" @click="$refs.calendar.next()">
                    <v-icon small>
                        mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>

                <!-- <v-col cols="2" class="s-col-form">

                </v-col> -->
                <v-col lg="3" md="3" cols="6" class="s-col-form">
                    <c-select-area full clearable noDefault v-model="AreID" label="Área" v-if="$fun.isAdmin()" :typeArea="3" />
                </v-col>
            </v-toolbar>

        </v-sheet>
        <v-sheet height="auto">
            <v-calendar locale="es" ref="calendar" v-model="value" color="primary" @click:event="showEvent" :start="start" :end="end" @moved="moved">
                <template #day="{ past, date}">

                    <v-card style="padding-bottom:12px;" v-for="d in requiremennDay(date)" elevation="0" :key="d.DerID">
                        <div v-for="(item, index) in Object.keys(d.items)" :key="index">
                            <div style="color:;background: antiquewhite;">
                                <v-row style="margin:auto">
                                    <h4 class="ml-3">{{item}}</h4><span>
                                    </span>
                                </v-row>
                            </div>
                            <v-card v-for="m in d.items[item]" dense outlined style="border-radius: 6px;" class="mb-1">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <h4 style="margin-bottom: 5px;margin-top:-10px;" class="text-center">
                                                <b style="text-decoration:underline"><a @click="openDialog(m)">Req Nro.: {{ m.ReqDocumentNumber }}</a></b>
                                                <span style="margin-top: -25px; margin-bottom: -70px;display: inline-table;">
                                                    <v-checkbox v-model="m.Check" @click="ClickCheck(m)" /></span>

                                            </h4>
                                            <v-divider> </v-divider>
                                            <h4 class="text--primary text-center" style="margin-bottom: 1px">
                                                <span>{{ m.DerDescription }} : {{m.DerQuantity}} {{m.UnitMeasurement}}<br></span><br>
                                            </h4>
                                            <v-divider> </v-divider>

                                            <v-card elevation="0" dense class="mt-1">
                                                <h2 class="text-center">{{m.ReqTimeElapsed}}</h2>
                                                <br><b>Solicitado por:</b> <br>{{m.UsrName}}
                                                <br><b>Area:</b> {{m.AreName}}
                                                <br><b>Prioridad:</b>
                                                <v-chip x-small :color="m.TypePriorityColor">

                                                </v-chip>
                                                {{ m.TypePriorityName }}

                                                <br>
                                                <v-chip x-small v-if="m.RequiredVisit" color="info">{{m.RequiredVisit ? 'R. Visita Campo' : ''}}</v-chip>
                                                <v-divider> </v-divider>
                                                <br>
                                                <v-divider> </v-divider>
                                                <h3 class="text-center">{{m.TypeEARName}}</h3>

                                            </v-card>
                                            <v-divider> </v-divider>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>

                            </v-card>
                        </div>
                    </v-card>
                </template>
            </v-calendar>
        </v-sheet>
    </v-col>
    <v-dialog v-if="dialogEdit" v-model="dialogEdit" persistent width="100%">
        <v-card>
            <lgs-requirement-edit :value="parentID" @close="dialogEdit = false"></lgs-requirement-edit>
        </v-card>
    </v-dialog>

    <div>
        <v-dialog v-if="dialogOrder" v-model="dialogOrder" persistent transition="dialog-bottom-transition">
            <order-edit @onClose="dialogOrder = false;refresh();" @clearItems='itemsSAP=[];' @onCloseClear="dialogOrder = false;refresh();itemsSAP=[];" :typeOrder="filter.TypeRequirement" :order="order" :value="null"></order-edit>
        </v-dialog>
    </div>

    <div>
        <v-dialog v-if="dialogCotizar" v-model="dialogCotizar" persistent transition="dialog-bottom-transition">
            <order-cotization-new @onClose="dialogCotizar = false; refresh();" :order="order"></order-cotization-new>
        </v-dialog>
    </div>

    <modal-loading :processing="processing" :messageProcessing="messageProcessing"></modal-loading>

</v-row>
</template>

<script>
import _sRequirementDetail from "@/services/Logistics/RequirementDetailService";
import _sGeneral from "@/services/HelperService.js";

//Components
import LgsRequirementEdit from "@/views/Logistics/Requirement/LgsRequirementEdit";
import OrderEdit from "@/views/Logistics/OrderPurchase/OrderPurchaseEdit.vue";
import OrderCotizationNew from '../OrderCotization/OrderCotizationNew.vue';

export default {
    components: {
        LgsRequirementEdit,
        OrderEdit,
        OrderCotizationNew
    },
    data: () => ({

        processing: false,
        messageProcessing: "",

        dates: [],
        week: 0,
        value: null,
        title: "",
        dialog: false,
        events: [],
        start: null,
        end: null,
        typeOrder: 1,
        parentID: 0,
        dialogApproval: false,
        dialogEdit: false,
        AreID: null,
        itemsSAP: [],
        order: {
            detail: []
        },
        dialogCotizar: false,
        dialogOrder: false,
        filter: {
            TypeRequirement: null,
        },
    }),

    created() {
        if (!this.$fun.isAdmin()) this.AreID = this.$fun.getUserInfo().CtrContract.AreID;
        this.calendar(this.$moment(new Date()));
    },

    watch: {
        AreID() {
            this.calendar(this.$moment(new Date()));
        }
    },

    methods: {

        refresh() {
            this.itemsSAP = []
            this.calendar(this.$moment(new Date()));
        },

        openDialog(item) {
            console.log(item)
            this.parentID = item.ReqID
            this.dialogEdit = true
        },

        moved({
            date
        }) {
            this.calendar(date);
        },

        requiremennDay(date) {
            return this.dates.filter((x) => {
                return x.start === date;
            });
        },

        calendar(date) {

            this.dates = [];
            this.title = this.$moment(date)
                .format("MMMM")
                .toUpperCase();
            this.start = this.$moment(date).format("YYYY-MM-01");
            let days = this.$moment(this.start).daysInMonth();
            this.end = this.$moment(this.start)
                .add(days, "d")
                .format("YYYY-MM-DD");

            let obj = {
                length: -1,
                filter: {
                    AreID: this.AreID
                },
            };

            _sRequirementDetail.pagination(obj, this.$fun.getUserID()).then((r) => {
                var daysInMonth = this.$moment().daysInMonth() + 6;
                let i = 0;
                while (i <= daysInMonth) {
                    i++;
                    var current = this.$moment(this.start).date(i).format("YYYY-MM-DD");

                    let date = {
                        start: current,
                        items: r.data.data.filter(
                            (x) => this.$moment(x.ReqAuthorizationDate).format("YYYY-MM-DD") == current
                        ),
                    };
                    var newObj = _.groupBy(date.items, "TypeRequirementName");

                    date.items = newObj
                    console.log(date)
                    this.dates.push(date);

                }

            })

        },

        showEvent() {
            return this.dates;
        },

        ClickCheck(item) {
            if (item.Check) {
                this.itemsSAP.push(item)
            } else {
                this.itemsSAP = this.itemsSAP.filter(
                    x => x.DerID != item.DerID,
                );
            }
        },

        previewCotizar() {
            if (this.itemsSAP.length == 0) {
                this.$fun.alert("No ha seleccionado items", "warning")
                return
            }

            this.order.detail = this.itemsSAP;
            var countPurcharse = 0,
                countService = 0,
                distinct = 0

            for (let i = 0; i < this.order.detail.length; i++) {

                if (this.order.detail[i].TypeRequirement == 1) countPurcharse = countPurcharse + 1
                if (this.order.detail[i].TypeRequirement == 3) countService = countService + 1

                this.order.detail[i].OddMeasureUnit = this.order.detail[i].UnitMeasurement;
                this.order.detail[i].OddDescription = this.order.detail[i].DerDescription;
                this.order.detail[i].CtdDescription = this.order.detail[i].DerDescription;
                this.order.detail[i].CtdObservation = this.order.detail[i].DerObservation;
                this.order.detail[i].OddObservation = this.order.detail[i].DerObservation;
                this.order.detail[i].CtdQuantity = this.order.detail[i].DerQuantity;
                //this.order.detail[i].ReqDayExpiration = this.order.detail[i].ReqDayExpiration;

                this.order.detail[i].Line = i + 1;

            }

            this.itemsSAP.forEach(element => {
                if (element.ReqID !== this.itemsSAP[0].ReqID) {
                    distinct = distinct + 1
                }
            });

            if (distinct > 0) {
                this.$fun.alert("Ha seleccionado items de varios REQUERIMIENTOS, por favor verifique", "error")
                return
            }

            if (countPurcharse > 0 && countService > 0) {
                this.$fun.alert("Upps, no puede Cotizar con diferente tipo de REQUERIMIENTO, por favor verifique", "error")
                return
            } else {
                if (this.itemsSAP.length > 0) {
                    this.order.ReqID = this.itemsSAP[0].ReqID
                    this.order.TypeOrder = this.itemsSAP[0].TypeRequirement
                    this.dialogCotizar = true
                };
            }
        },

        previewSap() {
            /* console.log(this.model)
            if (this.model) {
                this.$emit('selected', this.itemsSAP);
                return;
            }*/

            if (this.itemsSAP.length == 0) {
                this.$fun.alert("No ha seleccionado items", "warning")
                return
            }

            this.order.CttID = null
            this.order.detail = this.itemsSAP;

            var countPurcharse = 0,
                countService = 0,
                distinct = 0

            for (let i = 0; i < this.order.detail.length; i++) {
                if (this.order.detail[i].TypeRequirement == 1) countPurcharse = countPurcharse + 1
                if (this.order.detail[i].TypeRequirement == 3) countService = countService + 1

                this.order.detail[i].OddMeasureUnit = this.order.detail[i].UnitMeasurement;
                this.order.detail[i].OddDescription = this.order.detail[i].DerDescription;
                this.order.detail[i].OddObservation = this.order.detail[i].DerObservation;
                this.order.detail[i].OddQuantity = this.order.detail[i].DerQuantity;
                this.filter.TypeRequirement = this.order.detail[i].TypeRequirement
                this.order.detail[i].Line = i + 1;
            }

            this.itemsSAP.forEach(element => {
                if (element.ReqID !== this.itemsSAP[0].ReqID) {
                    distinct = distinct + 1
                }
            });

            if (distinct > 0) {
                this.$fun.alert("Ha seleccionado items de varios REQUERIMIENTOS, por favor verifique", "error")
                this.filter.TypeRequirement = null
                return
            }

            if (countPurcharse > 0 && countService > 0) {
                this.$fun.alert("Upps, no puede crear una OC/OS con diferente tipo de Requerimiento, por favor verifique", "error")
                this.filter.TypeRequirement = null
                return
            } else {
                if (this.itemsSAP.length > 0) {

                    this.order.CttID = null

                    this.order.ReqID = this.itemsSAP[0].ReqID
                    this.dialogOrder = true
                };
            }

            /* */

        },
    },
}
</script>

<style>
.v-card--reveal {
    align-items: center;
    bottom: 0;
    left: 0;
    justify-content: center;
    opacity: 1;
    width: 100%;
    z-index: 99999;
}
</style>
